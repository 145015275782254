import { gql, useMutation } from "@apollo/client";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ModifyScenarioMutation,
  ModifyScenarioMutationVariables,
  ModifyIssueMutation,
  ModifyIssueMutationVariables,
  ModifyActionPlanMutation,
  ModifyActionPlanMutationVariables,
  ModifyAssetsMutation,
  ModifyAssetsMutationVariables,
  Priority,
  Severity,
  ModifyTaskMutation,
  ModifyTaskMutationVariables,
} from "API";
import { ModuleStatus } from "utils/formatStatus";
import { HGForm, HGFormSubmit } from "common/HGForm";
import { usePermissions } from "common/Permissions";
import {
  modifyScenario,
  modifyIssue,
  modifyActionPlan,
  modifyAssets,
  modifyTask,
  setUserApproverItem,
  removeUserApproverFromItem,
} from "graphql/mutations";
import { useItemEditContext } from "common/bulkEdit";
import { useScenarioComparisonContext } from "../Modules/Customers/Risk/Comparison/TableComparison";
import React, { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { cancelPopupMessage } from "utils/globalVariables";
import { ActionPlanTypeEnum, useUserEnum } from "utils/dataFormatters";
import { useGetScenariosInGroup } from "utils/connectors/scenarioConnectors";
import { useGetIssuesInGroup } from "utils/connectors/issueConnectors";
import {
  useGetActionPlanTasks,
  useGetActionPlansInGroup,
} from "utils/connectors/actionPlanConnectors";
import { useGetCategoriesInGroup } from "utils/connectors/categoryConnectors";
import { useGetSupportsInGroup } from "utils/connectors/supportConnectors";
import { useGetLocationsInGroup } from "utils/connectors/locationConnectors";
import { useGetAssetsInGroup } from "utils/connectors/assetsConnectors";
import { sleep } from "utils/useSleep";
import Dialog from "react-bootstrap-dialog";
import ToastifyQueue from "./Overlays/ToastifyQueue";
import { useAssetComparisonContext } from "Modules/Customers/HIPAARiskAssessment/Assets/BulkEditAssets";
import { HGDatePicker } from "./HGForm/HGDatePicker";
import { HGCheckBox, HGNumber, HGSelect } from "./HGForm/Inputs";

export const CreateBulkEdit = ({ items, type }) => {
  const { group, userId } = usePermissions();
  const [showCreate, setShowCreate] = useState(false);

  const { refetch } = useGetScenariosInGroup();
  const refetchIssue = useGetIssuesInGroup();
  const refetchActionPlans = useGetActionPlansInGroup();
  const refetchAssets = useGetAssetsInGroup();
  const actionPlanTask = useGetActionPlanTasks();
  const allUsers = useUserEnum(group.id);
  const users = allUsers.filter((item) => item.id !== null);

  const scenarioComparison = useScenarioComparisonContext();
  const itemEdit = useItemEditContext();
  const asseetItemEdit = useAssetComparisonContext();
  const dialog = useRef(null);

  const [updateScenario] = useMutation<
    ModifyScenarioMutation,
    ModifyScenarioMutationVariables
  >(gql(modifyScenario), {
    onCompleted: () => {
      ToastifyQueue("Risk Scenario Updated Successfully", "success");
      sleep(500).then(refetch);
      setShowCreate(false);
    },
  });

  const [updateIssue] = useMutation<
    ModifyIssueMutation,
    ModifyIssueMutationVariables
  >(gql(modifyIssue), {
    onCompleted: () => {
      ToastifyQueue("Issue Updated Successfully", "success");
      sleep(500).then(refetchIssue?.refetch);
      setShowCreate(false);
    },
  });
  const [updateActionPlan] = useMutation<
    ModifyActionPlanMutation,
    ModifyActionPlanMutationVariables
  >(gql(modifyActionPlan), {
    onCompleted: () => {
      ToastifyQueue("Action Plan Updated Successfully", "success");
      sleep(500).then(refetchActionPlans?.refetch);
      setShowCreate(false);
    },
  });

  const [updateAssets] = useMutation<
    ModifyAssetsMutation,
    ModifyAssetsMutationVariables
  >(gql(modifyAssets), {
    onCompleted: () => {
      ToastifyQueue("Assets Updated Successfully", "success");
      sleep(500).then(refetchAssets?.refetch);
      setShowCreate(false);
    },
  });

  const [updateTask] = useMutation<
    ModifyTaskMutation,
    ModifyTaskMutationVariables
  >(gql(modifyTask), {
    onCompleted: () => {
      ToastifyQueue("Tasks Updated Successfully", "success");
      sleep(500).then(() => actionPlanTask?.refetch?.());
      setShowCreate(false);
    },
  });

  const [_setUserApprover] = useMutation(gql(setUserApproverItem), {
    onCompleted: () => {
      sleep(500).then(refetch);
    },
  });

  const [_removeUserApprover] = useMutation(gql(removeUserApproverFromItem), {
    onCompleted: () => {
      sleep(500).then(refetch);
    },
  });

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreate(false);
    }
  }

  const defaultModifyItem = {
    owner: "Off",
    severity: "Off",
    priority: "Off",
    qty: "Off",
    support: "Off",
    category: "Off",
    location: "Off",
    dueDate: "Off",
    status: "Off",
    approver: "Off",
    type: "Off",
  };

  const { _supportsData } = useGetSupportsInGroup();
  const { _locationsData } = useGetLocationsInGroup();
  const { _categoriesData } = useGetCategoriesInGroup();

  const [_modifyItem, _setModifyItem] = useState(defaultModifyItem);
  const isUnchecked = Object.values(_modifyItem).every(
    (val, i, arr) => val === "Off"
  );
  return (
    <>
      <Dialog ref={dialog} />
      <Button
        className="Modify-text"
        variant="light"
        onClick={() => {
          setShowCreate(true);
        }}
      >
        <FontAwesomeIcon className="mr-1" icon={faEdit} />
        Modify
      </Button>
      <Modal
        show={showCreate}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          onSubmit={async (input) => {
            let criteriaMet: boolean | null = null;
            (dialog?.current as any).show?.({
              title: "Confirm Bulk Update",
              body: (
                <div>
                  <p>Are you sure you want to update all selected items ?</p>
                </div>
              ),
              actions: [
                Dialog.Action(
                  "No",
                  () => {
                    criteriaMet = false;
                    return "You clicked no";
                  },
                  "btn-danger"
                ),
                Dialog.Action(
                  "Yes",
                  () => {
                    criteriaMet = true;
                    return "You clicked yes";
                  },
                  "btn-primary"
                ),
              ],
            });
            while (criteriaMet === null) {
              await sleep(100);
            }
            if (criteriaMet && type === "Scenario") {
              const arr = scenarioComparison.checked.map((obj) => ({
                id: obj,
                groupID: group.id,
                ...input,
              }));
              return await updateScenario({
                variables: {
                  input: arr,
                },
              });
            }
            if (criteriaMet && type === "Issue") {
              const arr = itemEdit.checked.map((obj) => ({
                id: obj,
                groupID: group.id,
                ...input,
              }));
              const removeItemApproverArray = itemEdit.checkedData.map(
                (item) => ({
                  itemID: item.id,
                  userID:
                    item.issueApprovers?.items?.slice(-1)?.[0]?.userID ??
                    "undefined",
                })
              );
              const setApproverItemArray = arr.map((item) => ({
                itemID: item.id,
                userID: input?.approver,
              }));
              // remove ApproverEdit
              await _removeUserApprover({
                variables: {
                  input: removeItemApproverArray,
                },
              });
              // add approver
              await _setUserApprover({
                variables: {
                  input: setApproverItemArray,
                },
              });
              return await updateIssue({
                variables: {
                  input: arr.map(({ approver, ...rest }) => rest),
                },
              });
            }
            if (criteriaMet && type === "ActionPlan") {
              const arr = itemEdit.checked.map((obj) => ({
                id: obj,
                groupID: group.id,
                ...input,
              }));
              const removeItemApproverArray = itemEdit.checkedData.map(
                (item) => ({
                  itemID: item.id,
                  userID:
                    item.actionplanApprovers?.items?.slice(-1)?.[0]?.userID ??
                    "undefined",
                })
              );
              const setApproverItemArray = arr.map((item) => ({
                itemID: item.id,
                userID: input?.approver,
              }));
              // remove ApproverEdit
              await _removeUserApprover({
                variables: {
                  input: removeItemApproverArray,
                },
              });
              // add approver
              await _setUserApprover({
                variables: {
                  input: setApproverItemArray,
                },
              });

              return await updateActionPlan({
                variables: {
                  input: arr.map(({ approver, ...rest }) => rest),
                },
              });
            }
            if (criteriaMet && type === "Assets") {
              const arr = asseetItemEdit.checked.map((obj) => ({
                id: obj,
                groupID: group.id,
                ...input,
              }));
              return await updateAssets({
                variables: {
                  input: arr,
                },
              });
            }
            if (criteriaMet && type === "Task") {
              const arr = itemEdit.checked.map((obj) => ({
                id: obj,
                groupID: group.id,
                ...input,
              }));
              return await updateTask({
                variables: {
                  input: arr,
                },
              });
            }
            return false;
          }}
        >
          <Modal.Header>Modify {type}</Modal.Header>
          <Modal.Body>
            {(() => {
              switch (type) {
                case "Scenario":
                  return (
                    <div>
                      <HGSelect
                        options={users}
                        name="owner"
                        label="Owner"
                        defaultValue={userId}
                      />
                    </div>
                  );
                case "ActionPlan":
                  return (
                    <>
                      <HGCheckBox
                        id="owner"
                        name="modifyItem"
                        label="Owner"
                        checked={_modifyItem.owner === "owner"}
                        checkGroup="modifyItem"
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            owner: prior.owner === "Off" ? "owner" : "Off",
                          }))
                        }
                      />
                      {_modifyItem?.owner === "owner" ? (
                        <HGSelect
                          options={users}
                          name="owner"
                          defaultValue={userId}
                        />
                      ) : null}
                      <HGCheckBox
                        id="approver"
                        name="modifyItem"
                        label="Approver"
                        checked={_modifyItem.approver === "approver"}
                        checkGroup="modifyItem"
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            approver:
                              prior.approver === "Off" ? "approver" : "Off",
                          }))
                        }
                      />
                      {_modifyItem?.approver === "approver" ? (
                        <HGSelect
                          options={users}
                          name="approver"
                          defaultValue={userId}
                        />
                      ) : null}
                      <HGCheckBox
                        id="priority"
                        checked={_modifyItem.priority === "priority"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            priority:
                              prior.priority === "Off" ? "priority" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Priority"
                      />
                      {_modifyItem?.priority === "priority" ? (
                        <HGSelect name="priority" enumObj={Priority} />
                      ) : null}
                      <HGCheckBox
                        id="type"
                        checked={_modifyItem.type === "type"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            type: prior.type === "Off" ? "type" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Type"
                      />
                      {_modifyItem?.type === "type" ? (
                        <HGSelect
                          options={ActionPlanTypeEnum}
                          name="type"
                          defaultValue=""
                        />
                      ) : null}
                    </>
                  );
                case "Issue":
                  const issueOwners = users;
                  issueOwners.unshift({ id: "Null", title: "Not Set" });
                  return (
                    <>
                      <HGCheckBox
                        id="owner"
                        checked={_modifyItem.owner === "owner"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            owner: prior.owner === "Off" ? "owner" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Owner"
                      />
                      {_modifyItem?.owner === "owner" ? (
                        <HGSelect
                          options={issueOwners}
                          name="owner"
                          defaultValue={"Null"}
                        />
                      ) : null}
                      <HGCheckBox
                        id="approver"
                        checked={_modifyItem.approver === "approver"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            approver:
                              prior.approver === "Off" ? "approver" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Approver"
                      />
                      {_modifyItem?.approver === "approver" ? (
                        <HGSelect
                          options={users}
                          name="approver"
                          defaultValue={userId}
                        />
                      ) : null}
                      <HGCheckBox
                        id="priority"
                        checked={_modifyItem.priority === "priority"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            priority:
                              prior.priority === "Off" ? "priority" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Priority"
                      />
                      {_modifyItem?.priority === "priority" ? (
                        <HGSelect
                          name="priority"
                          labelClass="issue-priority"
                          enumObj={Priority}
                        />
                      ) : null}
                      <HGCheckBox
                        id="severity"
                        checked={_modifyItem.severity === "severity"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            severity:
                              prior.severity === "Off" ? "severity" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Severity"
                      />
                      {_modifyItem?.severity === "severity" ? (
                        <HGSelect name="severity" enumObj={Severity} />
                      ) : null}
                    </>
                  );
                case "Assets":
                  return (
                    <>
                      <HGCheckBox
                        id="qty"
                        checked={_modifyItem.qty === "qty"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            qty: prior.qty === "Off" ? "qty" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Qty"
                      />
                      {_modifyItem?.qty === "qty" ? (
                        <HGNumber name="qty" min={0} />
                      ) : null}
                      <HGCheckBox
                        id="categories"
                        checked={_modifyItem.category === "category"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            category:
                              prior.category === "Off" ? "category" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Category"
                      />
                      {_modifyItem?.category === "category" ? (
                        <HGSelect
                          name="category"
                          options={_categoriesData || []}
                        />
                      ) : null}
                      <HGCheckBox
                        id="supports"
                        checked={_modifyItem.support === "support"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            support:
                              prior.support === "Off" ? "support" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Support"
                      />
                      {_modifyItem?.support === "support" ? (
                        <HGSelect
                          name="support"
                          options={_supportsData || []}
                        />
                      ) : null}
                      <HGCheckBox
                        id="locations"
                        checked={_modifyItem.location === "location"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            location:
                              prior.location === "Off" ? "location" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Location"
                      />
                      {_modifyItem?.location === "location" ? (
                        <HGSelect
                          name="location"
                          options={_locationsData || []}
                        />
                      ) : null}
                    </>
                  );
                case "Task":
                  return (
                    <>
                      <HGCheckBox
                        id="owner"
                        checked={_modifyItem.owner === "owner"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            owner: prior.owner === "Off" ? "owner" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Owner"
                      />
                      {_modifyItem?.owner === "owner" ? (
                        <HGSelect
                          options={users}
                          name="owner"
                          defaultValue={userId}
                        />
                      ) : null}
                      <HGCheckBox
                        id="priority"
                        checked={_modifyItem.priority === "priority"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            priority:
                              prior.priority === "Off" ? "priority" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Priority"
                      />
                      {_modifyItem?.priority === "priority" ? (
                        <HGSelect
                          name="priority"
                          labelClass="task-priority"
                          enumObj={Priority}
                        />
                      ) : null}
                      <HGCheckBox
                        id="status"
                        checked={_modifyItem.status === "status"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            status: prior.status === "Off" ? "status" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Status"
                      />
                      {_modifyItem?.status === "status" ? (
                        <HGSelect
                          name="status"
                          labelClass="task-status"
                          enumObj={ModuleStatus}
                        />
                      ) : null}
                      <HGCheckBox
                        id="dueDate"
                        checked={_modifyItem.dueDate === "dueDate"}
                        onChange={() =>
                          _setModifyItem(({ ...prior }) => ({
                            ...prior,
                            dueDate:
                              prior.dueDate === "Off" ? "dueDate" : "Off",
                          }))
                        }
                        name="modifyItem"
                        label="Due Date"
                      />
                      {_modifyItem.dueDate === "dueDate" ? (
                        <HGDatePicker name="dueDate" />
                      ) : null}
                    </>
                  );
                default:
                  break;
              }
            })()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit disable={type === "Scenario" ? false : isUnchecked} />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
